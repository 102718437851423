import React from 'react';
import { Link, graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import FeatureStat from 'components/philanthropy/FeatureStat';

interface Props {
  data: {
    salisburyFamily: Core.ImageAttributes;
    giving: Core.ImageAttributes;
    love: Core.ImageAttributes;
    globe: Core.ImageAttributes;
    book: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { salisburyFamily, giving, love, globe, book } = props.data;

  return (
    <Layout departmentSlug="philanthropy" title="Philanthropy">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Philanthropy</Core.DepartmentLabel>
        <Core.Flag>Shape the World</Core.Flag>
        <br />
        <br />
        <br />
        <br />

        <Core.ContentSection>
          <Core.SubHeading>
            <Core.BoldText>
              Making an impact can start at any level, big or small. But every
              gift helps us Shape the World. From fueling discovery, to
              supporting students, learn why the below school supporters choose
              to give.
            </Core.BoldText>
          </Core.SubHeading>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={
            <>
              <Core.Caption>
                A <Core.BoldText>major gift</Core.BoldText> is a large donation
                that can be given to any cause at JHU SAIS.
              </Core.Caption>
              <Core.HorizontalRule />
            </>
          }
        >
          <Core.Flag>Story No. 1</Core.Flag>
          <Core.PrimaryHeading>Major Gifts</Core.PrimaryHeading>
          <Core.LargeText fontStyle="sans">
            Michael D. White ’76 wanted to start a legacy that focused on the
            most important thing: students.
          </Core.LargeText>
          <p>
            Knowing that the school needed more fellowship programs, he started
            an{' '}
            <b>
              <Link to="/winter-2019/philanthropy">incentivizing match</Link>
            </b>
            . His major gift doubles the impact of newly endowed fellowships.
          </p>
          <p>
            Since its inception, 20 new fellowships have been established as a
            result of Michael’s generosity, and awarded students are forever
            indebted to him and the names below. The impact this has on students
            will live on in generations to come.
          </p>
          <br />
          <br />
          <Core.TertiaryHeading>Fellowships</Core.TertiaryHeading>
          <ul>
            <li>Sean Ryan and Jennifer Lind Fellowship </li>
            <li>Mutwakil A. Amin Fellowship</li>
            <li>Helmut and Marjorie Sonnenfeldt Fellowship</li>
            <li>Thomas A. Schlenker Fellowship</li>
            <li>Shirley Archer Salisbury Fellowship </li>
            <li>Julie Reinganum Fellowship</li>
            <li>Larkin Dawn Family Fellowship</li>
            <li>Vos Family Fellowship</li>
            <li>Dr. Hisham Sharabi Memorial Scholarship </li>
            <li>John and Susan Fung Fellowship</li>
            <li>Robert J. Hildreth Fellowship</li>
            <li>John and Susan Littlefield Fellowship</li>
            <li>Norton W. Bell Fellowship</li>
            <li>Jaeyoul Kim Student Fellowship</li>
            <li>Brzezinski Fellowship</li>
            <li>Robert K. Carr Fellowship</li>
            <li>John Kocjan Fellowship</li>
            <li>Alison Grabell Fellowship</li>
            <li>Kathryn Knowles Memorial Fund</li>
            <li>Habiboallah Amouzegar and Jahangar Amouzegar Fellowship</li>
          </ul>
          <Core.FullWidthImage
            image={salisburyFamily}
            alt="Salisbury Family"
            caption="Family photo of the Salisbury family. William and the Salisbury family established the Shirley Archer Salisbury Fellowship (“Fund”), which was matched by the Michael White Endowed Scholar Fund."
          />
        </Core.ContentSection>
        <Core.HorizontalRule />
        <Core.ContentSection
          marginalia={
            <>
              <Core.Caption>
                A <Core.BoldText>planned gift</Core.BoldText> ensures your
                philanthropic wishes are met through appreciated stock,
                retirement plans, or your will. These gifts not only allow the
                school to prepare for the future, but they also outlast you –
                making an ongoing impact for years to come. Learn more about
                gift planning at{' '}
                <a href="http://jhu.planyourlegacy.org/">
                  jhu.planyourlegacy.org
                </a>
                .
              </Core.Caption>
              <Core.HorizontalRule />
            </>
          }
        >
          <Core.Flag>Story No. 2</Core.Flag>
          <Core.PrimaryHeading>Planned Giving</Core.PrimaryHeading>
          <Core.LargeText fontStyle="sans">
            Daniel Serwer, an academic director of Conflict Management and
            American Foreign Policy at Johns Hopkins SAIS, wanted to leave more
            than just an impression in the classroom.
          </Core.LargeText>
          <p>
            He wanted to leave a legacy that would outlast him. This past year,
            he chose to create a planned gift, a source of funding that allows
            students to travel to conflict-ridden zones where they explore
            resolution strategies in real time.
          </p>
          <br />
          <br />
          <Core.TertiaryHeading>Planned Gift</Core.TertiaryHeading>
          <ul>
            <li>I. William Zartman Field Trip Fund</li>
          </ul>
        </Core.ContentSection>

        <Core.HorizontalRule />
        <Core.ContentSection
          marginalia={
            <>
              <Core.Caption>
                <Core.BoldText>The Fund for SAIS</Core.BoldText>, our annual
                giving program, serves as a critical source of funding for the
                school. It allows for more fellowships, internships and other
                financial aid support for current students. To learn more,{' '}
                <a href="https://sais.jhu.edu/giving#ways-help">click here</a>.
              </Core.Caption>
              <Core.HorizontalRule />
            </>
          }
        >
          <Core.Flag>Story No. 3</Core.Flag>
          <Core.PrimaryHeading>Annual Giving</Core.PrimaryHeading>
          <Core.LargeText fontStyle="sans">
            Sara O’Rourke ’13, CHS member, wanted to give back to the school in
            more ways than one.
          </Core.LargeText>
          <p>
            While she serves on the board of advisors, volunteers her time and
            resources to programs such as SAIS Women Lead, she wanted to do
            something more. She chose to help through the annual fund program, a
            type of gift that allows the school to provide funding for its
            highest priorities. Sara hopes her example teaches other recent
            graduates that any type of support, both in time and resources,
            makes an impact. “Paying it forward” is something within the
            school’s culture that she hopes gains momentum in the years to come.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.PrimaryHeading>
          Thank You for Supporting JHU SAIS
        </Core.PrimaryHeading>
        <Core.ContentSection
          marginalia={<Core.FullWidthImage image={giving} alt="Giving" />}
        >
          <Core.SecondaryHeading>Overall Giving Updates</Core.SecondaryHeading>
          <FeatureStat stat="2,098" label="donors this year" color="#52a9dc" />
          <FeatureStat
            stat="15%"
            label="increase in overall giving from recent graduates (2010-2018)"
            color="#52a9dc"
          />
          <FeatureStat stat="594" label="first time donors" color="#52a9dc" />
          <Core.SecondaryHeading>Small Gifts Add Up</Core.SecondaryHeading>
          <FeatureStat
            heading="$1,000 or less"
            stat="2,362"
            label="gifts created $417,585"
            color="#52a9dc"
          />
          <FeatureStat
            heading="$100 or less"
            stat="1,484"
            label="gifts created $78,859"
            color="#52a9dc"
          />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={<Core.FullWidthImage image={book} alt="Book" />}
        >
          <Core.SecondaryHeading>
            75th Anniversary Campaign
          </Core.SecondaryHeading>
          <p>
            To kick off our 75th anniversary celebration, we wanted this fiscal
            year to be a year of milestones. We set a goal of raising $15
            million in endowed fellowships – funding that created more student
            fellowships and programs to support both students of today and
            tomorrow. *As of 10/17/19
          </p>
          <FeatureStat
            heading="Result"
            stat="20"
            label="new fellowships were created this fiscal year"
            color="#99c355"
          />
          <FeatureStat
            heading="Result"
            stat="$15,449,959"
            label="in fellowship support was raised"
            color="#99c355"
          />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={<Core.FullWidthImage image={love} alt="Love" />}
        >
          <Core.SecondaryHeading>Love Your SAIS Week</Core.SecondaryHeading>
          <p>
            Our annual giving week (March 25 – April 2) this past spring was a
            major success. Thank you everyone for your support. We had:
          </p>
          <FeatureStat stat="141" label="total donors" color="#da3731" />
          <FeatureStat stat="39" label="new donors" color="#da3731" />
          <FeatureStat stat="$52,789" label="total raised*" color="#da3731" />
          <FeatureStat
            stat="$25,000"
            label="in matching funds"
            color="#da3731"
          />
          <FeatureStat
            stat="95%"
            label="of support came from alumni"
            color="#da3731"
          />
          <FeatureStat
            stat="5%"
            label="came from faculty, employees, and parents"
            color="#da3731"
          />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={<Core.FullWidthImage image={globe} alt="Globe" />}
        >
          <Core.SecondaryHeading>ONEHopkins</Core.SecondaryHeading>
          <p>
            Thank you for participating in ONEHopkins! On May 1, alumni and
            friends from across the Johns Hopkins community came together to
            support students and faculty around the globe.
          </p>
          <FeatureStat stat="102" label="SAIS alumni donors" color="#e7873c" />
          <FeatureStat stat="1400" label="alumni donors" color="#e7873c" />
          <FeatureStat
            stat="$420,000"
            label="unlocked in challenge gifts for Johns Hopkins"
            color="#e7873c"
          />
        </Core.ContentSection>

        <p>
          <i>Data is from Fiscal Year 2018-2019</i>
        </p>

        <Core.HorizontalRule />

        <Core.MoreResources>
          <p>
            To learn more about the Christian Herter Society, visit{' '}
            <a
              href="https://www.sais-jhu.edu/atoz/christian-herter-society#welcome"
              target="_blank"
              rel="noopener noreferrer"
            >
              sais-jhu.edu/atoz/christian-herter-society
            </a>
            .
          </p>
          <br />
          <p>
            To learn more about the Richard Nijkerk and Jesse Friedlander 75th
            Anniversary Christian Herter Society Challenge, visit the Giving tab
            on the anniversary website at{' '}
            <a
              href="https://sais.jhu.edu/giving/christian-herter-society"
              target="_blank"
              rel="noopener noreferrer"
            >
              75.SAIS-JHU.edu
            </a>
            .
          </p>
          <br />
          <p>
            To make a donation in honor of SAIS’ 75th anniversary, visit{' '}
            <a
              href="https://75.sais-jhu.edu/#giving"
              target="_blank"
              rel="noopener noreferrer"
            >
              75.sais-jhu.edu/#giving
            </a>
          </p>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    salisburyFamily: file(
      relativePath: { regex: $directory }
      name: { eq: "salisbury-family" }
    ) {
      ...ContentWidthImage
    }
    giving: file(relativePath: { regex: $directory }, name: { eq: "giving" }) {
      ...MarginaliaImage
    }
    love: file(relativePath: { regex: $directory }, name: { eq: "love" }) {
      ...MarginaliaImage
    }
    globe: file(relativePath: { regex: $directory }, name: { eq: "globe" }) {
      ...MarginaliaImage
    }
    book: file(relativePath: { regex: $directory }, name: { eq: "book" }) {
      ...MarginaliaImage
    }
  }
`;

export default DepartmentContent;
