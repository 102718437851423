import './FeatureStat.scss';

import React from 'react';

export interface Props {
  stat: string;
  label: string;
  heading?: string;
  color?: string;
}

const FeatureStat: React.FC<Props> = (props) => {
  const { stat, label, heading, color } = props;

  return (
    <div className="FeatureStat">
      {heading && <div className="FeatureStat-heading">{heading}</div>}
      <div className="FeatureStat-stat" style={{ color }}>
        {stat}
      </div>
      <div className="FeatureStat-label">{label}</div>
    </div>
  );
};

export default FeatureStat;
